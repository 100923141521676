import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PricesCell.scss';
import Money from 'common/components/Money/Money';
import isNumber from 'lodash/isNumber';
import { CurrencyEnum } from 'common/constants';
import ColoredDiff from 'common/components/ColoredDiff/ColoredDiff';
import MoneyDiff from 'common/components/MoneyDiff/MoneyDiff';

type PropsT = {
    price: number | null | undefined;
    targetPrice: number | null | undefined;
};

const cx = classNames.bind(styles);

const PricesCell: React.FC<PropsT> = React.memo((props) => {
    const { price, targetPrice } = props;

    return (
        <div className={cx('cost')}>
            {isNumber(targetPrice) ? (
                <>
                    <div>
                        <Money testSelector="target-price" amount={targetPrice} currency={CurrencyEnum.EUR} />
                    </div>
                    <div>
                        <MoneyDiff value={price} baseValue={targetPrice}>
                            {(diff) => (
                                <ColoredDiff isInverted diff={diff}>
                                    <Money testSelector="price" amount={price} currency={CurrencyEnum.EUR} />
                                </ColoredDiff>
                            )}
                        </MoneyDiff>
                    </div>
                </>
            ) : (
                <>
                    <div>-</div>
                    <div>
                        <Money testSelector="price" amount={price} currency={CurrencyEnum.EUR} />
                    </div>
                </>
            )}
        </div>
    );
});

export default PricesCell;
